<template>
    <el-form ref="form" :model="form" label-width="140px" class="elForm" :rules="rules">
        <el-form-item label="排名：">
            <el-input placeholder="请输入排名：" type="number" v-model="form.sort" style="width: 400px;"></el-input>
        </el-form-item>
        <el-form-item label="公告标题：" prop="title">
            <el-input placeholder="请输入公告标题" v-model="form.title" style="width: 700px"></el-input>
        </el-form-item>
        <el-form-item label="公告分类：" prop="classify_id">
            <el-select size="small" v-model="form.classify_id" clearable style="width: 700px">
                <el-option v-for="item in classify" :key="item.id" :label="item.classify_name" :value="item.id"></el-option>
            </el-select>
        </el-form-item>
        <el-form-item label="副标题：">
            <el-input placeholder="请输入副标题" v-model="form.subtitle" style="width: 700px"></el-input>
        </el-form-item>
        <el-form-item label="公告时间：" prop="add_time">
            <el-date-picker v-model="form.add_time" style="width: 700px" value-format="timestamp" type="datetime"
                placeholder="选择日期时间" size="small"></el-date-picker>
        </el-form-item>
        <el-form-item label="是否显示：">
            <el-switch v-model="form.is_show" :active-value="1" :inactive-value="0"></el-switch>
        </el-form-item>
        <el-form-item label="公告详情：" prop="details">
            <RichText :richTxt="form.details" @soninfo="val => (form.details = val)"></RichText>
        </el-form-item>
        <Preservation @preservation="onSubmit"></Preservation>
    </el-form>
</template>

<script>
import RichText from '@/components/richText';
import Preservation from '@/components/preservation';
export default {
    components: {
        RichText,
        Preservation
    },
    data () {
        return {
            id: '',
            classify: [],
            form: {
                sort: '',
                title: '',
                classify_id: '',
                subtitle: '',
                add_time: '',
                is_show: 1,
                details: ''
            },
            rules: {
                title: [
                    { required: true, message: '请输入分类名称', trigger: 'blur' },
                ],
                classify_id: [
                    { required: true, message: '请选择公告分类', trigger: 'change' },
                ],
                add_time: [
                    { required: true, message: '请选择公告时间', trigger: 'change' },
                ],
                details: [
                    { required: true, message: '请输入公告详情', trigger: 'blur' },
                ],
            },
            is_edit: false
        };
    },
    created () {
        let id = this.$route.query.id;
        console.log(this.$route)
        if (id) {

            this.id = id
            this.is_edit = true
            this.getDetail();
        }
        this.getClassify()
    },
    methods: {
        getClassify () {
            this.$axios.post(this.$api.repair.marketing.afficheClassifylists).then(res => {
                if (res.code == 0) {
                    this.classify = res.result;
                } else {
                    this.$message.error(res.msg);
                }
            });
        },
        getDetail () {
            this.$axios.post(this.$api.repair.marketing.afficheInfo, {
                id: this.id
            }).then(res => {
                if (res.code == 0) {
                    let info = res.result
                    this.form = {
                        sort: info.sort,
                        title: info.title,
                        classify_id: info.classify_id,
                        subtitle: info.subtitle,
                        add_time: info.add_time * 1000,
                        is_show: info.is_show,
                        details: info.details,
                    }
                } else {
                    this.$message.error(res.msg);
                }
            });
        },
        onSubmit () {
            this.$refs.form.validate((valid) => {
                if (valid) {
                    let url = this.is_edit ? this.$api.repair.marketing.afficheEdit : this.$api.repair.marketing.afficheAdd
                    let data = Object.assign({}, this.form)
                    data.add_time = data.add_time / 1000
                    if (this.is_edit) {
                        data.id = this.id
                    }
                    this.$axios.post(url, data).then(res => {
                        if (res.code == 0) {
                            this.$message.success((this.is_edit ? '编辑' : '添加') + '成功');
                            this.$router.go(-1)
                        } else {
                            this.$message.error(res.msg);
                        }
                    });
                } else {
                    console.log('error submit!!');
                    return false;
                }
            });
        },
    },
};
</script>

<style lang="less" scoped>
.elForm {
    background-color: #fff;
    padding: 30px;
}

.centerBtn {
    display: flex;
    justify-content: center;
    align-items: center;
}
</style>
